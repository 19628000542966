<template>
  <b-card
    :title="title"
  >

    <p
      v-if="!hasValues"
      class="text-muted text-center pt-50 mb-0"
    >
      {{ $t('therms.no-data-to-display') }}
    </p>
    <template v-else>
      <b-row
        v-if="!legend"
        class="mb-2"
      >
        <b-col cols="12">
          <b-form-checkbox
            v-model="cumulated"
            name="check-button"
            class="mr-0 mb-2"
            switch
            inline
          >
            {{ $t('therms.mode-cumulate') }}
          </b-form-checkbox>
        </b-col>

        <b-col
          class="col-12 col-sm-8 chart-options"
        >
          <b-form-group
            v-slot="{ ariaDescribedby }"
            :label="$t('therms.display-as')+' :'"
            class="mb-1 font-weight-bold"
          >
            <b-row>
              <b-col
                v-for="mode in displayModes"
                :key="mode.value"
                class="col-12 col-sm-4"
              >
                <b-form-radio
                  v-model="displayMode"
                  :aria-describedby="ariaDescribedby"
                  :value="mode.value"
                  class="mt-25 ml-50 ml-sm-0 font-weight-light"
                >
                  {{ mode.text }}
                </b-form-radio>

              </b-col>
            </b-row>
          </b-form-group>
        </b-col>

        <b-col
          class="col-12 col-sm-4 pl-md-2 chart-options"
        >
          <b-form-group
            v-slot="{ ariaDescribedby }"
            :label="$t('therms.unit')+' :'"
            class="mb-2 font-weight-bold"
          >
            <b-row>
              <b-col
                v-for="unit in units"
                :key="unit.key"
                class="col-12 col-sm-4"
              >
                <b-form-radio
                  v-model="displayUnit"
                  :aria-describedby="ariaDescribedby"
                  :value="unit.key"
                  class="mt-25 ml-50 ml-sm-0 font-weight-light"
                >
                  {{ unit.name }}
                </b-form-radio>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>

      <e-charts
        ref="line"
        autoresize
        :options="graph"
        theme="theme-color"
        auto-resize
        style="width: 100% !important;"
        :style="{height: height+'px'}"
      />

      <template v-if="legend">
        <div class="mt-2 pt-1" />
        <b-row>
          <b-col class="col-12 col-md-4 col-lg-3">
            <b-row>
              <b-col>
                <b-form-checkbox
                  v-model="cumulated"
                  name="check-button"
                  class="mr-0"
                  switch
                  inline
                >
                  {{ $t('therms.mode-cumulate') }}
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div class="pt-1 d-none d-md-block" />
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  :label="$t('therms.display-as')+' :'"
                  class="mb-1 mt-1 font-weight-bold"
                >
                  <b-form-radio
                    v-for="mode in displayModes"
                    :key="mode.value"
                    v-model="displayMode"
                    :aria-describedby="ariaDescribedby"
                    :value="mode.value"
                    class="mt-25 ml-50 font-weight-light"
                  >
                    {{ mode.text }}
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div class="pt-1 d-none d-md-block" />
                <b-form-group
                  v-slot="{ ariaDescribedby }"
                  :label="$t('therms.unit')+' :'"
                  class="mb-2 font-weight-bold"
                >
                  <b-form-radio
                    v-for="unit in units"
                    :key="unit.key"
                    v-model="displayUnit"
                    :aria-describedby="ariaDescribedby"
                    :value="unit.key"
                    class="mt-25 ml-50 font-weight-light"
                  >
                    {{ unit.name }}
                  </b-form-radio>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col class="col-12 col-md-8 col-lg-9">
            <b-row>
              <b-col>
                <TouchButton
                  size="xs"
                  class="mr-75"
                  outline
                  @action="selectAllSerie"
                >
                  {{ $t('therms.select-all') }}
                </TouchButton>
                <TouchButton
                  size="xs"
                  class="mr-75"
                  outline
                  @action="deselectAllSerie"
                >
                  {{ $t('therms.unselect-all') }}
                </TouchButton>
              </b-col>
            </b-row>
            <b-row class="mt-0">
              <b-col>
                <div class="mt-2 mb-75 font-weight-bold">
                  {{ $t('therms.filters') }} :
                </div>

                <TouchButton
                  v-for="(serie, key) in series"
                  :key="key"
                  :active="seriesToDisplay.indexOf(key) !== -1"
                  size="xs"
                  class="mr-50 mb-50"
                  :outline="true"
                  @action="toggleSerie(key)"
                >
                  <span class="d-flex align-items-center">
                    <span
                      style="width: 10px; height: 10px; display: inline-block; border-radius: 20px; margin-right: 8px"
                      :style="{'background-color' : colors[key]}"
                    />
                    {{ serie.name }}
                  </span>
                </TouchButton>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </template>
  </b-card>

</template>

<script>
import {
  BCard, BFormCheckbox, BRow, BCol, BFormGroup, BFormRadio,
} from 'bootstrap-vue'
import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/chart/bar'
import TouchButton from '@core/components/touch-button/TouchButton.vue'

const MODE_LINE = 'line'
const MODE_STEP = 'step'
const MODE_BAR = 'bar'

ECharts.registerTheme('theme-color', {
  backgroundColor: 'rgba(0,0,0,0)',
  textStyle: {},
  title: {
    textStyle: {
      color: '#666666',
    },
    subtextStyle: {
      color: '#999999',
    },
  },
  line: {
    itemStyle: {
      normal: {
        borderWidth: '1',
      },
    },
    lineStyle: {
      normal: {
        width: '1',
      },
    },
    symbolSize: '10',
    symbol: 'emptyCircle',
    smooth: false,
  },
  radar: {
    itemStyle: {
      normal: {
        borderWidth: '2',
      },
    },
    lineStyle: {
      normal: {
        width: '3',
      },
    },
    symbolSize: '10',
    symbol: 'emptyCircle',
    smooth: true,
  },
  bar: {
    itemStyle: {
      normal: {
        barBorderWidth: '0',
        barBorderColor: '#444444',
      },
      emphasis: {
        barBorderWidth: '0',
        barBorderColor: '#444444',
      },
    },
  },
  pie: {
    itemStyle: {
      normal: {
        borderWidth: '0',
        borderColor: '#444444',
      },
      emphasis: {
        borderWidth: '0',
        borderColor: '#444444',
      },
    },
  },
  scatter: {
    itemStyle: {
      normal: {
        borderWidth: '0',
        borderColor: '#444444',
      },
      emphasis: {
        borderWidth: '0',
        borderColor: '#444444',
      },
    },
  },
  boxplot: {
    itemStyle: {
      normal: {
        borderWidth: '0',
        borderColor: '#444444',
      },
      emphasis: {
        borderWidth: '0',
        borderColor: '#444444',
      },
    },
  },
  parallel: {
    itemStyle: {
      normal: {
        borderWidth: '0',
        borderColor: '#444444',
      },
      emphasis: {
        borderWidth: '0',
        borderColor: '#444444',
      },
    },
  },
  sankey: {
    itemStyle: {
      normal: {
        borderWidth: '0',
        borderColor: '#444444',
      },
      emphasis: {
        borderWidth: '0',
        borderColor: '#444444',
      },
    },
  },
  funnel: {
    itemStyle: {
      normal: {
        borderWidth: '0',
        borderColor: '#444444',
      },
      emphasis: {
        borderWidth: '0',
        borderColor: '#444444',
      },
    },
  },
  gauge: {
    itemStyle: {
      normal: {
        borderWidth: '0',
        borderColor: '#444444',
      },
      emphasis: {
        borderWidth: '0',
        borderColor: '#444444',
      },
    },
  },
  candlestick: {
    itemStyle: {
      normal: {
        color: '#d0648a',
        color0: '#ffffff',
        borderColor: '#d0648a',
        borderColor0: '#22c3aa',
        borderWidth: 1,
      },
    },
  },
  graph: {
    itemStyle: {
      normal: {
        borderWidth: '0',
        borderColor: '#444444',
      },
    },
    lineStyle: {
      normal: {
        width: 1,
        color: '#aaa',
      },
    },
    symbolSize: '10',
    symbol: 'emptyCircle',
    smooth: true,
    color: [
      '#4ea397',
      '#22c3aa',
      '#7bd9a5',
    ],
    label: {
      normal: {
        textStyle: {
          color: '#ffffff',
        },
      },
    },
  },
  map: {
    itemStyle: {
      normal: {
        areaColor: '#eeeeee',
        borderColor: '#999999',
        borderWidth: '0.5',
      },
      emphasis: {
        areaColor: 'rgba(34,195,170,0.25)',
        borderColor: '#22c3aa',
        borderWidth: '0.5',
      },
    },
    label: {
      normal: {
        textStyle: {
          color: '#28544e',
        },
      },
      emphasis: {
        textStyle: {
          color: 'rgb(52,158,142)',
        },
      },
    },
  },
  geo: {
    itemStyle: {
      normal: {
        areaColor: '#eeeeee',
        borderColor: '#999999',
        borderWidth: '0.5',
      },
      emphasis: {
        areaColor: 'rgba(34,195,170,0.25)',
        borderColor: '#22c3aa',
        borderWidth: '0.5',
      },
    },
    label: {
      normal: {
        textStyle: {
          color: '#28544e',
        },
      },
      emphasis: {
        textStyle: {
          color: 'rgb(52,158,142)',
        },
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#999999',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          'rgba(250,250,250,0.3)',
          'rgba(200,200,200,0.3)',
        ],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#999999',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          'rgba(250,250,250,0.3)',
          'rgba(200,200,200,0.3)',
        ],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#999999',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          'rgba(250,250,250,0.3)',
          'rgba(200,200,200,0.3)',
        ],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#999999',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: [
          'rgba(250,250,250,0.3)',
          'rgba(200,200,200,0.3)',
        ],
      },
    },
  },
  toolbox: {
    iconStyle: {
      normal: {
        borderColor: '#aaaaaa',
      },
      emphasis: {
        borderColor: '#666',
      },
    },
  },
  legend: {
    textStyle: {
      color: '#999999',
    },
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: '#ccc',
        width: 1,
      },
      crossStyle: {
        color: '#ccc',
        width: 1,
      },
    },
  },
  timeline: {
    lineStyle: {
      color: '#349e8e',
      width: 1,
    },
    itemStyle: {
      normal: {
        color: '#349e8e',
        borderWidth: '1',
      },
      emphasis: {
        color: '#57e8d2',
      },
    },
    controlStyle: {
      normal: {
        color: '#349e8e',
        borderColor: '#349e8e',
        borderWidth: '0',
      },
    },
    checkpointStyle: {
      color: '#22c3aa',
      borderColor: 'rgba(34,195,170,0.25)',
    },
    label: {
      normal: {
        textStyle: {
          color: '#349e8e',
        },
      },
    },
  },
  visualMap: {
    color: [
      '#d0648a',
      '#22c3aa',
      'rgba(123,217,165,0.2)',
    ],
  },
  dataZoom: {
    backgroundColor: '#fff',
    dataBackgroundColor: '#dedede',
    fillerColor: 'rgba(34,195,170,0.25)',
    handleColor: '#dddddd',
    handleSize: '100%',
    textStyle: {
      color: '#999',
    },
  },
  markPoint: {
    label: {
      normal: {
        textStyle: {
          color: '#ffffff',
        },
      },
      emphasis: {
        textStyle: {
          color: '#ffffff',
        },
      },
    },
  },
})

export default {
  components: {
    BCard,
    BFormCheckbox,
    BFormGroup,
    BFormRadio,
    BRow,
    BCol,
    ECharts,
    TouchButton,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    height: {
      type: Number,
      default: 400,
    },
    series: {
      required: true,
      type: Array,
    },
    seriesOptions: {
      default() {
        return {
          lineStyle: {
            width: 5,
          },
        }
      },
      type: Object,
    },
    categories: {
      required: true,
      type: Array,
    },
    defaultUnit: {
      required: true,
      type: String,
    },
    units: {
      required: true,
      type: Array,
    },
    filterSeries: {
      type: Boolean,
      default: true,
    },
    legend: {
      type: Boolean,
      default: false,
    },
    colors: {
      required: true,
      type: Array,
    },
    customOptions: {
      default() {
        return {}
      },
      type: Object,
    },
  },
  data() {
    return {
      cumulated: true,
      seriesToDisplay: [],
      displayMode: 'line',
      displayUnit: null,
    }
  },
  computed: {
    displayModes() {
      return [
        {
          value: MODE_LINE,
          text: this.$t('therms.lines-chart'),
        },
        {
          value: MODE_STEP,
          text: this.$t('therms.step-chart'),
        },
        {
          value: MODE_BAR,
          text: this.$t('therms.bar-chart'),
        },
      ]
    },
    getCurrentUnit() {
      return this.units.filter(e => (e.key === this.displayUnit))[0]
    },
    /**
     * @returns {{type: string, smooth: boolean}|{}|{step: string, type: string}|{type: string}}
     */
    getTypeSeriesOptions() {
      switch (this.displayMode) {
        case MODE_LINE:
          return {
            type: 'line',
            smooth: true,
            step: undefined,
          }
        case MODE_STEP:
          return {
            type: 'line',
            step: 'middle',
            smooth: false,
          }
        case MODE_BAR:
          return {
            type: 'bar',
            label: { align: 'right' },
          }
        default:
          return {}
      }
    },
    /**
     * @returns {boolean}
     */
    hasValues() {
      if (this.displayUnit === null) return false

      return this.series.length >= 1 && this.series.map(serie => serie[this.displayUnit].reduce((a, b) => a + b, 0))
        .reduce((a, b) => a + b, 0) >= 1
    },
    /**
     * @returns {{yAxis: {axisLabel: {formatter: (function(*): string)}, splitLine: {show: boolean}, type: string}, xAxis: [{axisLabel: {rotate: number, padding: number[]}, data: *[], boundaryGap: boolean}], color: *[], grid: {top: string, left: string, bottom: string, width: string, show: boolean, right: string, containLabel: boolean}, series: *, tooltip: {formatter: (function(*): string), trigger: string}, visualMap: [{min: number, max: number, show: boolean, type: string}]} & Object}
     */
    graph() {
      const option = {
        color: this.colors.filter((color, key) => this.seriesToDisplay.indexOf(key) !== -1),
        tooltip: {
          // Means disable default "show/hide rule".
          trigger: 'axis',
          formatter: params => `<strong  style="text-decoration: underline;">${params[0].axisValueLabel} </strong><br>
                <table>
                ${params.filter(a => (this.filterSeries ? a.data > 0 : true))
    .sort((a, b) => b.data - a.data)
    .map(e => `<tr><td><span style="border:1px solid #ffffff; width: 10px; height: 10px; background-color: ${e.color}; display: inline-block; border-radius: 20px; margin-right: 6px"></span><strong>${e.seriesName}</strong> :</td> <td>${window.numberFormat(e.data)} ${this.getCurrentUnit.name} </td></tr>`)
    .join('')}
                </table>`,
        },
        // Make gradient line here
        visualMap: [{
          show: true,
          type: 'continuous',
          min: 0,
          max: 400,
        }],
        grid: {
          width: '100%',
          left: '0px',
          right: '10px',
          top: '10px',
          bottom: '10px',
          show: true,
          containLabel: true,

        },
        xAxis: (() => {
          const data = [{
            data: this.categories,
            axisLabel: {
              rotate: 30,
              padding: [30, 0, 20, 0],
            },
          }]

          if (this.displayMode === MODE_BAR) {
            data[0].scale = true
          } else {
            data[0].boundaryGap = false
          }

          return data
        })(),
        yAxis: {
          type: 'value',
          splitLine: { show: false },
          axisLabel: {
            formatter: value => `${window.numberFormat(value)} ${this.getCurrentUnit.name}`,
          },

        },
        dataZoom: [{
          type: 'inside',
          start: 0,
          end: 100,
        }, {
          start: 0,
          end: 100,
          handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '60%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffsetX: 2,
            shadowOffsetY: 2,
          },
        }],
        series: this.seriesHandled.map(serie => {
          // eslint-disable-next-line no-param-reassign
          serie = Object.assign(serie, this.seriesOptions, this.getTypeSeriesOptions)
          // eslint-disable-next-line no-param-reassign
          serie.data = serie[this.displayUnit]
          return serie
        }),
      }

      return Object.assign(option, this.customOptions)
    },
    /**
     * @returns {*}
     */
    seriesHandled() {
      if (this.displayUnit === null) return []

      return JSON.parse(JSON.stringify(this.series))
        .filter((serie, key) => this.seriesToDisplay.indexOf(key) !== -1)
        .map(e => {
          e[this.displayUnit] = (() => {
            const a = []
            e[this.displayUnit].forEach((v, k) => {
              a.push(Number((k > 0 && this.cumulated ? a[a.length - 1] + v : v).toFixed(2)))
            })

            return a
          })()
          return e
        })
    },
  },
  mounted() {
    this.selectAllSerie()
    this.displayUnit = this.defaultUnit
  },
  methods: {
    toggleSerie(key) {
      if (this.seriesToDisplay.indexOf(key) === -1) {
        this.seriesToDisplay.push(key)
      } else {
        this.seriesToDisplay.splice(this.seriesToDisplay.indexOf(key), 1)
      }
    },
    selectAllSerie() {
      this.series.forEach((serie, key) => {
        this.seriesToDisplay.push(key)
      })
    },
    deselectAllSerie() {
      this.seriesToDisplay = []
    },
  },
}
</script>

<style scoped lang="scss">
</style>
