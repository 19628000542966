<template>
  <scroller>
    <NavButtonBar>
      <nav-button @action="$router.back()">
        <i class="fa-solid fa-angle-left" />
      </nav-button>
    </NavButtonBar>
    <h1 class="text-primary pb-0 mt-2">
      {{ $t('pages.campaign.title') }}
    </h1>
    <h5
      v-if="campaign !== null"
      class="text-ellipsis"
    >
      {{ campaign.name }}
    </h5>

    <div class="mt-3 mb-3">
      <template v-if="loader || campaign === null">
        <div class="d-flex align-items-center justify-content-center my-5 py-2">
          <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
          />
        </div>
      </template>

      <template v-else>
        <h4
          v-if="stats !== null"
          class="mb-50"
        >
          {{ stats.emoji }} {{ stats.name }}
        </h4>

        <template v-if="error">
          <div
            class="mb-5 text-muted text-center d-flex flex-column px-3 animate__animated animate__fadeIn animate__fast"
          >
            <h1 class="mt-3 mb-2">
              <i class="fa-regular fa-cloud-exclamation" />
            </h1>
            {{ $t('pages.campaign.one-crop.loading-error') }}

            <div>
              <TouchButton
                size="xs"
                class="mt-2"
                @action="()=>{
                  return loadData(true)
                }"
              >
                {{ $t('therms.retry') }}
              </TouchButton>
            </div>
          </div>
        </template>
        <template v-else-if="dataLoader">
          <div class="d-flex align-items-center justify-content-center my-5 py-2">
            <b-spinner
              variant="primary"
              small
              style="width: 3rem; height: 3rem;"
            />
          </div>
        </template>
        <template v-else-if="!dataLoader">
          <div
            v-if="stats !== null"
            class="d-flex mb-2 pb-50"
          >
            <h7>📦 &nbsp; <span class="text-primary pr-25">{{ numberFormat(stats.quantity) }}</span>
              {{ $t('units.package') }}
            </h7>
            <h7 class="ml-2">
              ⚖️ &nbsp; <span class="text-primary pr-25">{{ numberFormat(stats.total_weight) }}</span> {{
                $t('units.kg')
              }}
            </h7>
          </div>

          <ProductionProgressionCard
            class="mb-3 animate__animated animate__fadeInUp"
            :title="$t('therms.global-progression').toString()"
            :filter-series="false"
            :categories="progressionsChartData.categories"
            :colors="progressionsChartData.colors"
            :series="progressionsChartData.series"
            :series-options="progressionsChartData.seriesOptions"
            :default-unit="progressionsChartData.units[0].key"
            :units="progressionsChartData.units"
          />

          <template v-if="!isTablet">
            <touch-button-group class="w-100 mb-2 d-flex">
              <touch-button
                class="flex-fill"
                :class="{'active' : mode === mode_simplified}"
                ingroup
                outline
                thinkiness
                size="sm"
                style="width: 33%"
                @action="mode = mode_simplified"
              >
                <span class="wrap">{{ $t('therms.simplified-view') }}</span>
              </touch-button>
              <touch-button
                class="flex-fill"
                :class="{'active' : mode === mode_graphical}"
                ingroup
                outline
                thinkiness
                size="sm"
                style="width: 33%"
                @action="mode = mode_graphical"
              >
                <span class="wrap">{{ $t('therms.graphical-view') }}</span>
              </touch-button>
              <touch-button
                class="flex-fill"
                :class="{'active' : mode === mode_progressive}"
                ingroup
                outline
                thinkiness
                size="sm"
                style="width: 33%"
                @action="mode = mode_progressive"
              >
                <span class="wrap">{{ $t('therms.progressive-view') }}</span>
              </touch-button>
            </touch-button-group>

            <div v-if="mode === mode_simplified">
              <ProductionStatCard
                v-if="Object.keys(stats.types).length>=1"
                :title="$t('therms.per-crop-types').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :items="stats.types"
                :max-quantity="stats.total_weight"
              />

              <ProductionStatCard
                v-if="stats.options.includes('packings') && Object.keys(stats.packings).length>=1"
                :title="$t('therms.per-crop-packings').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :items="stats.packings"
                :max-quantity="stats.total_weight"
                variant="warning"
              />

              <ProductionStatCard
                v-if="stats.options.includes('grades') && Object.keys(stats.grades).length>=1"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.per-crop-grades').toString()"
                :items="stats.grades"
                :max-quantity="stats.total_weight"
                variant="danger"
              />

              <ProductionStatCard
                v-if="Object.keys(stats.plots).length>=1"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.per-plots').toString()"
                :items="stats.plots"
                :max-quantity="stats.total_weight"
                variant="info"
              />

              <ProductionStatCard
                v-if="Object.keys(stats.accounts).length>=1"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.per-accounts').toString()"
                :items="stats.accounts"
                :max-quantity="stats.total_weight"
                variant="danger"
              />
            </div>

            <div v-if="mode === mode_graphical">
              <ProductionBarChartCard
                v-if="Object.keys(stats.types).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-types').toString()"
                :labels="statsTypeChartData.labels"
                :values="statsTypeChartData.values"
                :color="statsTypeChartData.color"
              />
              <ProductionBarChartCard
                v-if="stats.options.includes('packings') && Object.keys(stats.packings).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-packings').toString()"
                :labels="statsPackingChartData.labels"
                :values="statsPackingChartData.values"
                :color="statsPackingChartData.color"
              />
              <ProductionBarChartCard
                v-if="stats.options.includes('grades') && Object.keys(stats.grades).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-grades').toString()"
                :labels="statsGradesChartData.labels"
                :values="statsGradesChartData.values"
                :color="statsGradesChartData.color"
              />
              <ProductionBarChartCard
                v-if="Object.keys(stats.plots).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-plots').toString()"
                :labels="statsPlotsChartData.labels"
                :values="statsPlotsChartData.values"
                :color="statsPlotsChartData.color"
              />
              <ProductionBarChartCard
                v-if="Object.keys(stats.accounts).length>=1"
                :unit="$t('units.kg').toString()"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-accounts').toString()"
                :labels="statsAccountsChartData.labels"
                :values="statsAccountsChartData.values"
                :color="statsAccountsChartData.color"
              />
            </div>

            <div v-if="mode === mode_progressive">
              <ProductionProgressionCard
                v-if="progressionsTypeChartData !== null"
                :legend="true"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-types').toString()"
                :categories="progressionsTypeChartData.categories"
                :colors="progressionsTypeChartData.colors"
                :series="progressionsTypeChartData.series"
                :series-options="progressionsTypeChartData.seriesOptions"
                :default-unit="progressionsTypeChartData.units[0].key"
                :units="progressionsTypeChartData.units"
              />
              <ProductionProgressionCard
                v-if="progressions.options.includes('packings') && Object.keys(progressions.packings).length>=1 && progressionsPackingChartData !== null"
                :legend="true"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-packings').toString()"
                :categories="progressionsPackingChartData.categories"
                :colors="progressionsPackingChartData.colors"
                :series="progressionsPackingChartData.series"
                :series-options="progressionsPackingChartData.seriesOptions"
                :default-unit="progressionsPackingChartData.units[0].key"
                :units="progressionsPackingChartData.units"
              />
              <ProductionProgressionCard
                v-if="progressions.options.includes('grades') && Object.keys(progressions.grades).length>=1 && progressionsGradesChartData !== null"
                :legend="true"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-crop-grades').toString()"
                :categories="progressionsGradesChartData.categories"
                :colors="progressionsGradesChartData.colors"
                :series="progressionsGradesChartData.series"
                :series-options="progressionsGradesChartData.seriesOptions"
                :default-unit="progressionsGradesChartData.units[0].key"
                :units="progressionsGradesChartData.units"
              />
              <ProductionProgressionCard
                v-if="progressionsPlotsChartData !== null"
                :legend="true"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-plots').toString()"
                :categories="progressionsPlotsChartData.categories"
                :colors="progressionsPlotsChartData.colors"
                :series="progressionsPlotsChartData.series"
                :series-options="progressionsPlotsChartData.seriesOptions"
                :default-unit="progressionsPlotsChartData.units[0].key"
                :units="progressionsPlotsChartData.units"
              />
              <ProductionProgressionCard
                v-if="progressionsAccountsChartData !== null"
                :legend="true"
                class="mb-1 animate__animated animate__fadeInUp"
                :title="$t('therms.analyze-per-accounts').toString()"
                :categories="progressionsAccountsChartData.categories"
                :colors="progressionsAccountsChartData.colors"
                :series="progressionsAccountsChartData.series"
                :series-options="progressionsAccountsChartData.seriesOptions"
                :default-unit="progressionsAccountsChartData.units[0].key"
                :units="progressionsAccountsChartData.units"
              />
            </div>
          </template>
          <template v-else>
            <div class="simplified-view">
              <b-row class="animate__animated animate__fadeInUp">
                <b-col
                  xl="12"
                  class="mb-1"
                >
                  <h6>{{ $t('therms.simplified-view') }}</h6>
                </b-col>
                <b-col
                  v-if="Object.keys(stats.types).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionStatCard
                    :title="$t('therms.per-crop-types').toString()"
                    class="full-height mb-1"
                    :items="stats.types"
                    :max-quantity="stats.total_weight"
                  />
                </b-col>

                <b-col
                  v-if="stats.options.includes('packings') && Object.keys(stats.packings).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionStatCard
                    :title="$t('therms.per-crop-packings').toString()"
                    class="full-height mb-1"
                    :items="stats.packings"
                    :max-quantity="stats.total_weight"
                    variant="warning"
                  />
                </b-col>
                <b-col
                  v-if="stats.options.includes('grades') && Object.keys(stats.grades).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionStatCard
                    class="full-height mb-1"
                    :title="$t('therms.per-crop-grades').toString()"
                    :items="stats.grades"
                    :max-quantity="stats.total_weight"
                    variant="danger"
                  />
                </b-col>
                <b-col
                  v-if="Object.keys(stats.plots).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionStatCard
                    class="full-height mb-1"
                    :title="$t('therms.per-plots').toString()"
                    :items="stats.plots"
                    :max-quantity="stats.total_weight"
                    variant="info"
                  />
                </b-col>
                <b-col
                  v-if="Object.keys(stats.accounts).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionStatCard
                    class="full-height mb-1"
                    :title="$t('therms.per-accounts').toString()"
                    :items="stats.accounts"
                    :max-quantity="stats.total_weight"
                    variant="danger"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="graphical-view">
              <b-row class="animate__animated animate__fadeInUp">
                <b-col
                  xl="12"
                  class="mb-1"
                >
                  <h6>{{ $t('therms.graphical-view') }}</h6>
                </b-col>
                <b-col
                  v-if="Object.keys(stats.types).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionBarChartCard
                    :unit="$t('units.kg').toString()"
                    class="mb-1"
                    :title="$t('therms.analyze-per-crop-types').toString()"
                    :labels="statsTypeChartData.labels"
                    :values="statsTypeChartData.values"
                    :color="statsTypeChartData.color"
                  />
                </b-col>
                <b-col
                  v-if="stats.options.includes('packings') && Object.keys(stats.packings).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionBarChartCard
                    :unit="$t('units.kg').toString()"
                    class="mb-1"
                    :title="$t('therms.analyze-per-crop-packings').toString()"
                    :labels="statsPackingChartData.labels"
                    :values="statsPackingChartData.values"
                    :color="statsPackingChartData.color"
                  />
                </b-col>
                <b-col
                  v-if="stats.options.includes('grades') && Object.keys(stats.grades).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionBarChartCard
                    :unit="$t('units.kg').toString()"
                    class="mb-1"
                    :title="$t('therms.analyze-per-crop-grades').toString()"
                    :labels="statsGradesChartData.labels"
                    :values="statsGradesChartData.values"
                    :color="statsGradesChartData.color"
                  />
                </b-col>
                <b-col
                  v-if="Object.keys(stats.plots).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionBarChartCard
                    :unit="$t('units.kg').toString()"
                    class="mb-1"
                    :title="$t('therms.analyze-per-plots').toString()"
                    :labels="statsPlotsChartData.labels"
                    :values="statsPlotsChartData.values"
                    :color="statsPlotsChartData.color"
                  />
                </b-col>
                <b-col
                  v-if="Object.keys(stats.accounts).length>=1"
                  xs="12"
                  md="6"
                  lg="6"
                  class="mb-2 pb-75"
                >
                  <ProductionBarChartCard
                    :unit="$t('units.kg').toString()"
                    class="mb-1"
                    :title="$t('therms.analyze-per-accounts').toString()"
                    :labels="statsAccountsChartData.labels"
                    :values="statsAccountsChartData.values"
                    :color="statsAccountsChartData.color"
                  />
                </b-col>
              </b-row>
            </div>
            <div class="progressive-view">
              <b-row class="animate__animated animate__fadeInUp">
                <b-col
                  xl="12"
                  class="mb-1"
                >
                  <h6>{{ $t('therms.progressive-view') }}</h6>
                </b-col>
                <b-col
                  v-if="progressionsTypeChartData !== null"
                  lg="12"
                  class="pb-75"
                >
                  <ProductionProgressionCard
                    :legend="true"
                    class="mb-1 animate__animated animate__fadeInUp"
                    :title="$t('therms.analyze-per-crop-types').toString()"
                    :categories="progressionsTypeChartData.categories"
                    :colors="progressionsTypeChartData.colors"
                    :series="progressionsTypeChartData.series"
                    :series-options="progressionsTypeChartData.seriesOptions"
                    :default-unit="progressionsTypeChartData.units[0].key"
                    :units="progressionsTypeChartData.units"
                  />
                </b-col>
                <b-col
                  v-if="progressions.options.includes('packings') && Object.keys(progressions.packings).length>=1 && progressionsPackingChartData !== null"
                  lg="12"
                  class="pb-75"
                >
                  <ProductionProgressionCard
                    :legend="true"
                    class="mb-1 animate__animated animate__fadeInUp"
                    :title="$t('therms.analyze-per-crop-packings').toString()"
                    :categories="progressionsPackingChartData.categories"
                    :colors="progressionsPackingChartData.colors"
                    :series="progressionsPackingChartData.series"
                    :series-options="progressionsPackingChartData.seriesOptions"
                    :default-unit="progressionsPackingChartData.units[0].key"
                    :units="progressionsPackingChartData.units"
                  />
                </b-col>
                <b-col
                  v-if="progressions.options.includes('grades') && Object.keys(progressions.grades).length>=1 && progressionsGradesChartData !== null"
                  lg="12"
                  class="pb-75"
                >
                  <ProductionProgressionCard
                    :legend="true"
                    class="mb-1 animate__animated animate__fadeInUp"
                    :title="$t('therms.analyze-per-crop-grades').toString()"
                    :categories="progressionsGradesChartData.categories"
                    :colors="progressionsGradesChartData.colors"
                    :series="progressionsGradesChartData.series"
                    :series-options="progressionsGradesChartData.seriesOptions"
                    :default-unit="progressionsGradesChartData.units[0].key"
                    :units="progressionsGradesChartData.units"
                  />
                </b-col>
                <b-col
                  v-if="progressionsPlotsChartData !== null"
                  lg="12"
                  class="pb-75"
                >
                  <ProductionProgressionCard
                    :legend="true"
                    class="mb-1 animate__animated animate__fadeInUp"
                    :title="$t('therms.analyze-per-plots').toString()"
                    :categories="progressionsPlotsChartData.categories"
                    :colors="progressionsPlotsChartData.colors"
                    :series="progressionsPlotsChartData.series"
                    :series-options="progressionsPlotsChartData.seriesOptions"
                    :default-unit="progressionsPlotsChartData.units[0].key"
                    :units="progressionsPlotsChartData.units"
                  />
                </b-col>
                <b-col
                  v-if="progressionsAccountsChartData !== null"
                  lg="12"
                  class="pb-75"
                >
                  <ProductionProgressionCard
                    :legend="true"
                    class="mb-1 animate__animated animate__fadeInUp"
                    :title="$t('therms.analyze-per-accounts').toString()"
                    :categories="progressionsAccountsChartData.categories"
                    :colors="progressionsAccountsChartData.colors"
                    :series="progressionsAccountsChartData.series"
                    :series-options="progressionsAccountsChartData.seriesOptions"
                    :default-unit="progressionsAccountsChartData.units[0].key"
                    :units="progressionsAccountsChartData.units"
                  />
                </b-col>
              </b-row>
            </div>
          </template>
        </template>
      </template>
    </div>
  </scroller>
</template>

<script>
import NavButtonBar from '@core/layouts/components/app-navbar/NavButtonBar.vue'
import NavButton from '@core/layouts/components/app-navbar/NavButton.vue'
import chartColors from '@core/mixins/charts/colors'
import { BRow, BSpinner, BCol } from 'bootstrap-vue'
import TouchButton from '@core/components/touch-button/TouchButton.vue'
import TouchButtonGroup from '@core/components/touch-button/TouchButtonGroup.vue'
// eslint-disable-next-line import/no-cycle
import { NAME_RESUME_CAMPAIGNS } from '@/router/routes/campaigns'
import ProductionStatCard from '@/views/components/ProductionStatCard.vue'
import ProductionBarChartCard from '@/views/components/ProductionBarChartCard.vue'
import ProductionProgressionCard from '@/views/components/ProductionProgressionCard.vue'

export default {
  components: {
    NavButtonBar,
    NavButton,
    BSpinner,
    ProductionProgressionCard,
    ProductionBarChartCard,
    TouchButtonGroup,
    TouchButton,
    ProductionStatCard,
    BRow,
    BCol,
  },
  data() {
    return {
      // UI
      loader: false,
      dataLoader: false,
      error: false,
      mode: 'simplified',
      quantity: null,
      total_weight: null,

      // data
      campaign: null,
    }
  },
  computed: {
    /**
     * @returns {boolean}
     */
    isTablet() {
      return window.isTablet()
    },
    /**
     * @returns {string}
     */
    mode_simplified() {
      return 'simplified'
    },
    /**
     * @returns {string}
     */
    mode_graphical() {
      return 'graphical'
    },
    /**
     * @returns {string}
     */
    mode_progressive() {
      return 'progressive'
    },
    campaignUnit() {
      let diffInMonth = this.$moment(this.campaign.end)
        .diff(this.$moment(this.campaign.start), 'months', true)

      if (diffInMonth < 0) {
        diffInMonth *= (-1)
      }

      if (diffInMonth >= 15) return 'YEAR'
      if (diffInMonth >= 2) return 'MONTH'
      return 'DAY'
    },
    /**
     * @returns {null|*}
     */
    stats() {
      if (this.loader || this.campaign === null || this.dataLoader) return null

      // eslint-disable-next-line radix
      return (this.campaign?.stats ?? []).filter(e => parseInt(e.id) === parseInt(this.$router.currentRoute.params.crop_id))[0]
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    statsTypeChartData() {
      return {
        labels: Object.values(this.stats.types)
          .map(e => e.name),
        values: Object.values(this.stats.types)
          .map(e => e.total_weight),
        color: chartColors.warningColorShade,
      }
    },
    /**
     * @returns {null|{series: *, categories: *, colors: *}}
     */
    progressionsTypeChartData() {
      if (this.progressions.types.length === 0) return null
      return {
        categories: this.progressions
          .types[0]
          .stats
          .map(e => {
            const d = (`${e.unit}`).split('-')
            while (d.length < 3) {
              d.push('01')
            }
            switch (this.campaignUnit) {
              case 'DAY':
                return this.$moment(d.join('-'))
                  .format('L')
              case 'MONTH':
                return this.$moment(d.join('-'))
                  .format('MMM YYYY')
              case 'YEAR':
                return this.$moment(d.join('-'))
                  .format('YYYY')
              default:
                return this.$moment(d.join('-'))
                  .calendar()
            }
          }),
        seriesOptions: {
          lineStyle: {
            width: this.progressions
              .types.length > 5 ? 2 : 5,
          },
        },
        units: [
          {
            key: 'weight',
            name: this.$t('units.kg'),
          },
          {
            key: 'quantity',
            name: this.$t('units.package'),
          },
        ],
        series: this.progressions
          .types
          .map(e => ({
            name: e.name,
            weight: e.stats.map(a => a.total_weight),
            quantity: e.stats.map(a => a.quantities),
          })),
        colors: this.progressions
          .types
          .map(() => window.randomColor()),
      }
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    statsPackingChartData() {
      return {
        labels: Object.values(this.stats.packings)
          .map(e => e.name),
        values: Object.values(this.stats.packings)
          .map(e => e.total_weight),
        color: chartColors.warningLightColor,
      }
    },
    /**
     * @returns {null|{series: *, categories: *, colors: *}}
     */
    progressionsPackingChartData() {
      if (!this.progressions.options.includes('packings') || Object.keys(this.progressions.packings).length === 0) return null

      return {
        categories: this.progressions
          .packings[0]
          .stats
          .map(e => {
            const d = (`${e.unit}`).split('-')
            while (d.length < 3) {
              d.push('01')
            }
            switch (this.campaignUnit) {
              case 'DAY':
                return this.$moment(d.join('-'))
                  .format('L')
              case 'MONTH':
                return this.$moment(d.join('-'))
                  .format('MMM YYYY')
              case 'YEAR':
                return this.$moment(d.join('-'))
                  .format('YYYY')
              default:
                return this.$moment(d.join('-'))
                  .calendar()
            }
          }),
        seriesOptions: {
          lineStyle: {
            width: this.progressions.packings.length > 5 ? 2 : 5,
          },
        },
        units: [
          {
            key: 'weight',
            name: this.$t('units.kg'),
          },
          {
            key: 'quantity',
            name: this.$t('units.package'),
          },
        ],
        series: this.progressions
          .packings
          .map(e => ({
            name: e.name,
            weight: e.stats.map(a => a.total_weight),
            quantity: e.stats.map(a => a.quantities),
          })),
        colors: this.progressions
          .packings
          .map(() => window.randomColor()),
      }
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    statsGradesChartData() {
      return {
        labels: Object.values(this.stats.grades)
          .map(e => e.name),
        values: Object.values(this.stats.grades)
          .map(e => e.total_weight),
        color: chartColors.lineChartDanger,
      }
    },
    /**
     * @returns {null|{series: *, categories: *, colors: *}}
     */
    progressionsGradesChartData() {
      if (!this.progressions.options.includes('grades') || Object.keys(this.progressions.grades).length === 0) return null
      return {
        categories: this.progressions
          .grades[0]
          .stats
          .map(e => {
            const d = (`${e.unit}`).split('-')
            while (d.length < 3) {
              d.push('01')
            }
            switch (this.campaignUnit) {
              case 'DAY':
                return this.$moment(d.join('-'))
                  .format('L')
              case 'MONTH':
                return this.$moment(d.join('-'))
                  .format('MMM YYYY')
              case 'YEAR':
                return this.$moment(d.join('-'))
                  .format('YYYY')
              default:
                return this.$moment(d.join('-'))
                  .calendar()
            }
          }),
        seriesOptions: {
          lineStyle: {
            width: this.progressions
              .grades.length > 5 ? 2 : 5,
          },
        },
        units: [
          {
            key: 'weight',
            name: this.$t('units.kg'),
          },
          {
            key: 'quantity',
            name: this.$t('units.package'),
          },
        ],
        series: this.progressions
          .grades
          .map(e => ({
            name: e.name,
            weight: e.stats.map(a => a.total_weight),
            quantity: e.stats.map(a => a.quantities),
          })),
        colors: this.progressions
          .grades
          .map(() => window.randomColor()),
      }
    },
    /**
     * @returns {{color: string, values: *[], labels: *[]}}
     */
    statsPlotsChartData() {
      return {
        labels: Object.values(this.stats.plots)
          .map(e => e.name + (e.name !== e.abbreviation ? ` (${e.abbreviation})` : '')),
        values: Object.values(this.stats.plots)
          .map(e => e.total_weight),
        color: chartColors.infoColorShade,
      }
    },
    /**
     * @returns {null|{series: *, categories: *, colors: *}}
     */
    statsAccountsChartData() {
      return {
        labels: Object.values(this.stats.accounts)
          .map(e => e.name),
        values: Object.values(this.stats.accounts)
          .map(e => e.total_weight),
        color: chartColors.lineChartDanger,
      }
    },
    /**
     * @returns {null|{series: *, categories: *, colors: *}}
     */
    progressionsPlotsChartData() {
      if (this.progressions.plots.length === 0) return null

      return {
        categories: this.progressions
          .plots[0]
          .stats
          .map(e => {
            const d = (`${e.unit}`).split('-')
            while (d.length < 3) {
              d.push('01')
            }
            switch (this.campaignUnit) {
              case 'DAY':
                return this.$moment(d.join('-'))
                  .format('L')
              case 'MONTH':
                return this.$moment(d.join('-'))
                  .format('MMM YYYY')
              case 'YEAR':
                return this.$moment(d.join('-'))
                  .format('YYYY')
              default:
                return this.$moment(d.join('-'))
                  .calendar()
            }
          }),
        seriesOptions: {
          lineStyle: {
            width: this.progressions
              .plots.length > 5 ? 2 : 5,
          },
        },
        units: [
          {
            key: 'weight',
            name: this.$t('units.kg'),
          },
          {
            key: 'quantity',
            name: this.$t('units.package'),
          },
        ],
        series: this.progressions
          .plots
          .map(e => ({
            name: e.name,
            weight: e.stats.map(a => a.total_weight),
            quantity: e.stats.map(a => a.quantities),
          })),
        colors: this.progressions
          .plots
          .map(() => window.randomColor()),
      }
    },
    /**
     * @returns {{series: *, seriesOptions: {lineStyle: {width: (number)}}, categories: *, units: [{name: VueI18n.TranslateResult, key: string},{name: VueI18n.TranslateResult, key: string}], colors: *}|null}
     */
    progressionsAccountsChartData() {
      if (this.progressions.accounts.length === 0) return null

      return {
        categories: this.progressions
          .accounts[0]
          .stats
          .map(e => {
            const d = (`${e.unit}`).split('-')
            while (d.length < 3) {
              d.push('01')
            }
            switch (this.campaignUnit) {
              case 'DAY':
                return this.$moment(d.join('-'))
                  .format('L')
              case 'MONTH':
                return this.$moment(d.join('-'))
                  .format('MMM YYYY')
              case 'YEAR':
                return this.$moment(d.join('-'))
                  .format('YYYY')
              default:
                return this.$moment(d.join('-'))
                  .calendar()
            }
          }),
        seriesOptions: {
          lineStyle: {
            width: this.progressions
              .accounts.length > 5 ? 2 : 5,
          },
        },
        units: [
          {
            key: 'weight',
            name: this.$t('units.kg'),
          },
          {
            key: 'quantity',
            name: this.$t('units.package'),
          },
        ],
        series: this.progressions
          .accounts
          .map(e => ({
            name: e.name,
            weight: e.stats.map(a => a.total_weight),
            quantity: e.stats.map(a => a.quantities),
          })),
        colors: this.progressions
          .accounts
          .map(() => window.randomColor()),
      }
    },
    /**
     * @returns {null|*}
     */
    progressions() {
      if (this.loader || this.campaign === null || this.dataLoader) return null

      // eslint-disable-next-line radix
      return (this.campaign?.progressions ?? []).filter(e => parseInt(e.id) === parseInt(this.$router.currentRoute.params.crop_id))[0]
    },
    /**
     * @returns {{series: [{data: *}], categories: *, colors: *[]}}
     */
    progressionsChartData() {
      return {
        categories: this.progressions
          .stats
          .map(e => {
            const d = (`${e.unit}`).split('-')
            while (d.length < 3) {
              d.push('01')
            }
            switch (this.campaignUnit) {
              case 'DAY':
                return this.$moment(d.join('-'))
                  .format('L')
              case 'MONTH':
                return this.$moment(d.join('-'))
                  .format('MMM YYYY')
              case 'YEAR':
                return this.$moment(d.join('-'))
                  .format('YYYY')
              default:
                return this.$moment(d.join('-'))
                  .calendar()
            }
          }),
        seriesOptions: {
          lineStyle: {
            width: 5,
          },
        },
        units: [
          {
            key: 'weight',
            name: this.$t('units.kg'),
          },
          {
            key: 'quantity',
            name: this.$t('units.package'),
          },
        ],
        series: [
          {
            name: this.stats.name,
            weight: this.progressions.stats.map(a => a.total_weight),
            quantity: this.progressions.stats.map(a => a.quantities),
          },
        ],
        colors: [
          this.progressions
            .color,
        ],
      }
    },
  },
  async mounted() {
    try {
      if (typeof this.$router.currentRoute.params.crop_id === 'undefined' || this.$router.currentRoute.params.crop_id === null) {
        throw new Error('Cannot find crop id')
      }
      this.campaign = await (this.$store.dispatch('campaigns/find', this.$router.currentRoute.params.campaign_id))
    } catch (err) {
      await this.$router.replace({ name: NAME_RESUME_CAMPAIGNS })
      throw err
    }

    await this.loadData()
  },
  methods: {
    /**
     * @param {Function} finishToRefresh
     * @returns {Promise<void>}
     */
    async refresh(finishToRefresh) {
      if (this.loader || this.campaign === null || this.dataLoader) {
        finishToRefresh()
        return
      }

      this.error = false

      try {
        await (this.$store.dispatch('campaigns/fetchStats', {
          id: this.campaign.id,
          force: true,
        }))
        await (this.$store.dispatch('campaigns/fetchProgression', {
          id: this.campaign.id,
          unit: this.campaignUnit,
          force: true,
        }))

        finishToRefresh()
      } catch (err) {
        this.error = true
        finishToRefresh()

        throw err
      }
    },
    /**
     * @param {Boolean} force
     * @returns {Promise<void>}
     */
    async loadData(force = false) {
      try {
        if (this.loader || this.campaign === null) return

        this.error = false
        this.dataLoader = true
        if (force) {
          await this.$store.commit('campaigns/clearStats', this.campaign.id)
        }

        await (this.$store.dispatch('campaigns/fetchStats', {
          id: this.campaign.id,
          force,
        }))
        await (this.$store.dispatch('campaigns/fetchProgression', {
          id: this.campaign.id,
          unit: this.campaignUnit,
          force,
        }))

        this.dataLoader = false
      } catch (e) {
        setTimeout(() => {
          this.error = true
        }, 100)
      }
    },
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/base/bootstrap-extended/_include';
@import '@core/scss/base/components/_include';

</style>
